import React, { useEffect, useState } from 'react';

const loadScript = async (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = false; // This is required for synchronous execution
  script.setAttribute('data-manual-render', 'true');
  script.setAttribute('data-zone-id', '231260');
  document.head.appendChild(script);
};

const ILX = (ilxOptions) => {
  useEffect(() => {
    loadScript(
      'https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/js/jtknet.js'
    );
    loadScript('https://api.lincx.com/load');

    // }, []);

    // useEffect(() => {
    let ilxdiv = document.createElement('div');
    ilxdiv.id = 'interLincxTarget';
    document.body.appendChild(ilxdiv);

    /**
     * @param {string} pageCode
     * @param {string} zoneId
     * @param {string} vertical - refinance or purchase
     *
     */
    const options = {
      pageCode: ilxOptions[0].pageCode,
      zoneId: ilxOptions[0].zoneId,
      vertical: ilxOptions[0].vertical,
    };
    const interLincxTarget = document.getElementById('interLincxTarget');
    const jtkCheck = setInterval(() => {
      if (
        typeof window !== 'undefined' &&
        window.jtknet !== undefined &&
        window.lincx !== undefined
      ) {
        window.jtknet
          .ilx(options)
          .then((ilxData) => {
            console.log('DL ILX Loaded');
            window.lincx(interLincxTarget, ilxData);
          })
          .catch((error) => {
            console.log(error);
          });

        // this pixel fires on page load
        const cip = new Image();
        // true/false if you want the aff id to be passes
        cip.src = window.jtknet.pixelString(ilxOptions[0].pixel, false);
        cip.setAttribute('width', '1');
        cip.setAttribute('height', '1');
        cip.setAttribute('border', '0');
        document.body.appendChild(cip);
        clearInterval(jtkCheck);
      }
    }, 100);
    setTimeout(() => {
      clearInterval(jtkCheck);
    }, 10000);
  }, [
    ilxOptions.pageCode,
    ilxOptions.vertical,
    ilxOptions.zoneId,
    ilxOptions.pixel,
  ]);

  return <div id="interLincxTarget" />;
};

export default ILX;
