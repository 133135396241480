import React, { useEffect, useState } from 'react';

const HealthHeader = ({ params }) => {
  return (
    <div id="MaTarget">
      <div id="auto-headline-test">
        <img
          className="checkMark"
          src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/animation_200_checkmark.gif"
          alt="Checkmark"
        />
        <div className="auto-headline-title">
          <h1>Don't Miss Out</h1>
          <p>
            Hi, There Are Free, No Obligation Offers Below, Just For You in Your
            area.
          </p>
          <p>Click the Rates Below to Compare Your Savings Options!</p>
        </div>
      </div>
    </div>
  );
};

export default HealthHeader;
