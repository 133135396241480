import React, { useEffect, useState } from 'react';
// import useTestVariation from '../hooks/useTestVariation';
import queryString from 'query-string';
// import splitUrlTests from '../utils/splitUrlTests';
import Header from '../components/Header';
import Layout from '../components/layout';
import MediaAlpha from '../components/MediaAlpha';
import Adbutler from '../components/Adbutler';
import ILX from '../components/Interlincx';

const parsed = queryString.parse(
  typeof window !== 'undefined' ? window.location.search : ''
);

// sets adbutler zone id's based on param data
let ilxData = [
  //purchase
  {
    typ: 'GCPDL',
    pageCode: 'GCPDL',
    zoneId: 'ye1z89',
    vertical: 'purchase',
    pixel:
      'https://suited45trk.com/i.ashx?E=J6DfRnOX01beCWgbVk9RaucV2pp1jy6g&s1=NoAdButlerGCPDL',
  },
  {
    typ: 'BCPDL',
    pageCode: 'BCPDL',
    zoneId: '6f44bt',
    vertical: 'purchase',
    pixel:
      'https://suited45trk.com/i.ashx?E=vtpVkiqeLQYMOVimZEXdfucV2pp1jy6g&s1=NoAdButlerBCPDL',
  },
  //refinance
  {
    typ: 'GCRDL',
    pageCode: 'GCRDL',
    zoneId: 'o7fgrb',
    vertical: 'refinance',
    pixel:
      'https://suited45trk.com/i.ashx?E=m3UpU4%2FWjeqpW6HArFOu9ucV2pp1jy6g&s1=NoAdbutlerGCRDL',
  },
  {
    typ: 'BCRDL',
    pageCode: 'BCRDL',
    zoneId: 'gs8zou',
    vertical: 'refinance',
    pixel:
      'https://suited45trk.com/i.ashx?E=Cjgx9QJGtf78UBycQty%2FiucV2pp1jy6g&s1=NoAdButlerBCRDL',
  },
  // home services
  // windows
  {
    typ: 'WINDL',
    pageCode: 'WINDL',
    zoneId: 'ov892g',
    vertical: 'refinance',
    pixel:
      'https://suited45trk.com/i.ashx?E=iLjkFHce37PTjN%2b5UnB6uucV2pp1jy6g&s1=NoAdButler',
  },
  //Solar
  {
    typ: 'SRODL',
    pageCode: 'SRODL',
    zoneId: 'm7611l',
    vertical: 'refinance',
    pixel:
      'https://suited45trk.com/i.ashx?E=KggRH2BPP8DNtfqST5YJnucV2pp1jy6g&s1=NoAdButler',
  },
  //roofing
  {
    typ: 'ROODL',
    pageCode: 'ROODL',
    zoneId: 'una62v',
    vertical: 'refinance',
    pixel:
      'https://suited45trk.com/i.ashx?E=Q0wnpqDLGn3TjN%2b5UnB6uucV2pp1jy6g&s1=NoAdButler',
  },
  //Bath
  {
    typ: 'BARDL',
    pageCode: 'BARDL',
    zoneId: '2qeds1',
    vertical: 'refinance',
    pixel:
      'https://suited45trk.com/i.ashx?E=cRpIe%2f%2fnaMXAs4flweSnNecV2pp1jy6g&s1=SUB_ID',
  },
];
let ilxType = '';
let adButlerIds = [];
const goodCreditArr = ['excellent', 'good', 'average'];
const { LoanPurpose, CreditRating, SiteVertical } = parsed;
const hs = 'hs_';

const coinFlip = () => {
  let res;
  const fiftyFifty = Math.random() < 0.5;
  if (fiftyFifty) {
    res = '1';
  } else {
    res = '0';
  }

  return res;
};
let runIlx = false;
let runAdButler = false;
// let runMediaAlpha = false;
const version = coinFlip();

const TyLanding = () => {
  const [hideHeader, setHideHeader] = useState(true);
  const [runMediaAlpha, setRunMediaAlpha] = useState(false);
  // const hasVariation = true;

  // fire the test
  // useEffect(() => {
  //   splitUrlTests.pat127(version, setHideHeader, LoanPurpose);
  // }, [hasVariation]);

  // Show header for these loan purposes or site verticals still
  const showLoanPurposeHeader = ['auto', 'health', 'home', 'life', 'med'];

  useEffect(() => {
    if (
      showLoanPurposeHeader.includes(LoanPurpose) ||
      SiteVertical === 'ins_health'
    ) {
      setHideHeader(false);
    }
    if (LoanPurpose === 'health' || SiteVertical === 'ins_health') {
      setRunMediaAlpha(true);
      // window?.jtknet?.doMediaAlphaOverlay(true, SiteVertical, 'DuplicateLead');
    }
  }, []);

  if (SiteVertical && SiteVertical.includes(hs)) {
    switch (SiteVertical) {
      case 'hs_solar':
        ilxType = 'SRODL';
        runIlx = true;

        break;
      case 'hs_roofing':
        ilxType = 'ROODL';
        runIlx = true;

        break;
      case 'hs_ha':
        // prettier-ignore
        adButlerIds = ['467062', '467063', '467064', '467067', '467066'];
        break;
      case 'hs_window':
        ilxType = 'WINDL';
        runIlx = true;

        break;
      case 'hs_br':
        ilxType = 'BARDL';
        // prettier-ignore
        // adButlerIds = ['493449', '493450', '493451', '493452', '493453'];
        runIlx = true;
        break;
      default:
        adButlerIds = ['467062', '467063', '467064', '467067', '467066'];
        break;
    }
  } else {
    switch (LoanPurpose) {
      case 'auto':
        // prettier-ignore
        adButlerIds = ['467056','467057','467058','467059','467061'];
        runAdButler = true;
        break;
      case 'home':
        // prettier-ignore
        adButlerIds = ['467078','467079','467080','467081','467082'];
        runAdButler = true;
        break;
      case 'life':
        // prettier-ignore
        adButlerIds = ['467200','467201','467202','467203','467204'];
        runAdButler = true;
        break;
      case 'med':
        // prettier-ignore
        adButlerIds = ['467307','467308','467309','467310','467311'];
        runAdButler = true;
        break;
      case 'Refinance':
        // prettier-ignore
        if (goodCreditArr.includes(CreditRating)) {
          ilxType = 'GCRDL';
            // prettier-ignore
            adButlerIds = ['290074','290075','290076','290077','290078']; // GCR
          } else {
            ilxType = 'BCRDL';
            // prettier-ignore
            adButlerIds = ['290069','290070','290071','290072','290073']; // BCR
          }
        runIlx = true;
        break;
      case 'Purchase':
        // prettier-ignore
        if (goodCreditArr.includes(CreditRating)) {
            // prettier-ignore
            ilxType = 'GCPDL';
            adButlerIds = ['290064','290065','290066','290067','290068']; // GCP
          } else {
            // prettier-ignore
            ilxType = 'BCPDL';
            adButlerIds =  ['290059','290060','290061','290062','290063'] // BCP
          }
        runIlx = true;
        break;
      default:
        // prettier-ignore
        adButlerIds = ['290074','290075','290076','290077','290078'];
        break;
    }
  }

  return (
    <>
      {!hideHeader && <Header />}
      <Layout hideHeader={hideHeader} isfluid={false}>
        {runIlx && ILX(ilxData.filter((item) => item.typ === ilxType))}
        {runAdButler && <Adbutler adButlerIds={adButlerIds} />}
        {runMediaAlpha && <MediaAlpha siteVertical={SiteVertical} />}
      </Layout>
      {runMediaAlpha && (
        <p class="creditible">
          <span class="srOnly">Form successfully submitted.</span> We accept
          advertising compensation from companies that appear on the site, which
          impacts the location and order in which brands (and/or their products)
          are presented.
        </p>
      )}
    </>
  );
};

export default TyLanding;
