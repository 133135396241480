import React, { useEffect } from 'react';
import HealthHeader from './HealthHeader';

const loadScript = async (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = false; // This is required for synchronous execution
  script.setAttribute('data-manual-render', 'true');
  script.setAttribute('data-zone-id', '231260');
  document.head.appendChild(script);
};

const MediaAlpha = ({ siteVertical }) => {
  useEffect(() => {
    loadScript(
      'https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/js/jtknet.js'
    );
    loadScript('https://api.lincx.com/load');

    const jtkCheck = setInterval(() => {
      if (
        typeof window !== 'undefined' &&
        window.jtknet !== undefined &&
        window.lincx !== undefined
      ) {
        window.jtknet.doMediaAlphaOverlay(true, 'ins_health', 'DuplicateLead');

        clearInterval(jtkCheck);
      }
    }, 100);
    setTimeout(() => {
      clearInterval(jtkCheck);
    }, 10000);
  }, []);

  return (
    <>
      <HealthHeader />
      <div id="maTarget" style={{ margin: '20px auto 40px' }} />
    </>
  );
};

export default MediaAlpha;
